@charset "UTF-8";

@import "Library/Framework/colors";
@import "Library/Framework/variables";

#nav-button{
	display: none !important;
}

.slider,
.logo{
	display: none !important;
}